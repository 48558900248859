import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4a138a04")
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
_popScopeId()

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useVeBal from '@/composables/useVeBAL';

/**
 * TYPES
 */
type Props = {
  expectedVeBalAmount: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    expectedVeBalAmount: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  expectedVeBalAmount: string;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { veBalTokenInfo } = useVeBal();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('getVeBAL.lockForm.summary.receive.title')), 1),
      _createElementVNode("div", null, _toDisplayString(__props.expectedVeBalAmount != null
            ? _unref(fNum2)(__props.expectedVeBalAmount, _unref(FNumFormats).token)
            : '-') + " " + _toDisplayString(_unref(veBalTokenInfo).symbol), 1)
    ])
  ]))
}
}

})