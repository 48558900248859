import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-70a6d7db")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "summary-item-row" }
const _hoisted_5 = {
  key: 0,
  class: "summary-item-row"
}
const _hoisted_6 = { class: "summary-item-row" }
const _hoisted_7 = { class: "summary-item-row" }
const _hoisted_8 = { class: "flex items-center" }
_popScopeId()

import { format } from 'date-fns';
import { computed } from 'vue';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import { LockType } from '@/components/forms/lock_actions/LockForm/types';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useVeBal from '@/composables/useVeBAL';
import { bnum } from '@/lib/utils';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';
import { Pool } from '@/services/pool/types';

import VeBalTooltipExplainer from './VeBalTooltipExplainer.vue';

/**
 * TYPES
 */
type Props = {
  lockablePool: Pool;
  totalLpTokens: string;
  lockEndDate: string;
  lockAmount: string;
  expectedVeBalAmount: string;
  lockType: LockType[];
  veBalLockInfo: VeBalLockInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    totalLpTokens: { type: String, required: true },
    lockEndDate: { type: String, required: true },
    lockAmount: { type: String, required: true },
    expectedVeBalAmount: { type: String, required: true },
    lockType: { type: Array, required: true },
    veBalLockInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: Pool;
  totalLpTokens: string;
  lockEndDate: string;
  lockAmount: string;
  expectedVeBalAmount: string;
  lockType: LockType[];
  veBalLockInfo: VeBalLockInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { veBalTokenInfo } = useVeBal();

/**
 * COMPUTED
 */
const poolShares = computed(() =>
  bnum(props.lockablePool.totalLiquidity).div(props.lockablePool.totalShares)
);

const fiatTotalLockedAmount = computed(() =>
  poolShares.value.times(props.veBalLockInfo.lockedAmount).toString()
);

const fiatTotalLockAmount = computed(() =>
  poolShares.value.times(props.lockAmount).toString()
);

const fiatTotalLpTokens = computed(() =>
  poolShares.value.times(props.totalLpTokens).toString()
);

const isExtendLockOnly = computed(
  () =>
    props.lockType.length === 1 && props.lockType.includes(LockType.EXTEND_LOCK)
);

const isIncreaseLockOnly = computed(
  () =>
    props.lockType.length === 1 &&
    props.lockType.includes(LockType.INCREASE_LOCK)
);

// const fiatWeeklyYield = computed(() => '0');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('getVeBAL.previewModal.summary.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_unref(isExtendLockOnly) || _unref(isIncreaseLockOnly)
              ? _ctx.$t('getVeBAL.previewModal.summary.totalAlreadyLocked')
              : _ctx.$t('getVeBAL.previewModal.summary.totalToLock')), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(
              _unref(isIncreaseLockOnly) ? _unref(fiatTotalLockedAmount) : _unref(fiatTotalLpTokens),
              _unref(FNumFormats).fiat
            )), 1)
      ]),
      (_unref(isIncreaseLockOnly))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('getVeBAL.previewModal.summary.increasedLockAmount')), 1),
            _createElementVNode("div", null, _toDisplayString(_unref(fNum2)(_unref(fiatTotalLockAmount), _unref(FNumFormats).fiat)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, _toDisplayString(_unref(isExtendLockOnly)
              ? _ctx.$t('getVeBAL.previewModal.summary.newLockEndDate')
              : _ctx.$t('getVeBAL.previewModal.summary.lockEndDate')), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(format)(new Date(__props.lockEndDate), _unref(PRETTY_DATE_FORMAT))), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('getVeBAL.previewModal.summary.totalVotingEscrow')), 1),
        _createElementVNode("div", _hoisted_8, [
          _createTextVNode(_toDisplayString(_unref(fNum2)(__props.expectedVeBalAmount, _unref(FNumFormats).token)) + " " + _toDisplayString(_unref(veBalTokenInfo).symbol) + " ", 1),
          _createVNode(VeBalTooltipExplainer, {
            expectedVeBalAmount: __props.expectedVeBalAmount,
            lockEndDate: __props.lockEndDate,
            totalLpTokens: __props.totalLpTokens
          }, null, 8, ["expectedVeBalAmount", "lockEndDate", "totalLpTokens"])
        ])
      ])
    ])
  ]))
}
}

})