import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-577808b1")
const _hoisted_1 = { class: "divide-y text-sm dark:divide-gray-900" }
const _hoisted_2 = { class: "rounded-t bg-gray-50 p-3 dark:bg-gray-700" }
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = { class: "text-secondary mb-3" }
const _hoisted_5 = { class: "mb-1 flex items-center whitespace-nowrap" }
const _hoisted_6 = { class: "w-full table-fixed" }
const _hoisted_7 = { class: "table-header-cell" }
const _hoisted_8 = { class: "table-header-cell" }
const _hoisted_9 = { class: "table-body-cell" }
const _hoisted_10 = { class: "table-body-cell" }
_popScopeId()

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useVeBal from '@/composables/useVeBAL';

/**
 * TYPES
 */
type Props = {
  totalLpTokens: string;
  lockEndDate: string;
  expectedVeBalAmount: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    totalLpTokens: { type: String, required: true },
    lockEndDate: { type: String, required: true },
    expectedVeBalAmount: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  totalLpTokens: string;
  lockEndDate: string;
  expectedVeBalAmount: string;
}) {



/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { veBalTokenInfo } = useVeBal();
const { t } = useI18n();

/**
 * COMPUTED
 */
const conversationTableRows = computed(() => [
  {
    label: t('getVeBAL.lockForm.lockPeriods.1y'),
    value: `~1 ${veBalTokenInfo.value.symbol}`,
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.6m'),
    value: `~0.5 ${veBalTokenInfo.value.symbol}`,
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.3m'),
    value: `~0.25 ${veBalTokenInfo.value.symbol}`,
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createBlock(_component_BalTooltip, {
    width: "72",
    noPad: "",
    class: "ml-2"
  }, {
    activator: _withCtx(() => [
      _createVNode(_component_BalIcon, {
        name: "info",
        size: "sm",
        class: "text-secondary"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString(_unref(fNum2)(__props.expectedVeBalAmount, _unref(FNumFormats).token)) + " " + _toDisplayString(_unref(veBalTokenInfo).symbol), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(
              'getVeBAL.previewModal.summary.totalVotingEscrowTooltip.explainer'
            )), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("table", _hoisted_6, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t(
                      'getVeBAL.previewModal.summary.totalVotingEscrowTooltip.table.lockPeriod'
                    )), 1),
                  _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t(
                      'getVeBAL.previewModal.summary.totalVotingEscrowTooltip.table.totalVeBAL'
                    )), 1)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conversationTableRows), (row, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    _createElementVNode("td", _hoisted_9, _toDisplayString(row.label), 1),
                    _createElementVNode("td", _hoisted_10, _toDisplayString(row.value), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})