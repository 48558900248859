import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-af662d90")
const _hoisted_1 = { class: "group flex w-full items-center justify-between rounded-xl p-4" }
const _hoisted_2 = { class: "transition-colors group-hover:text-blue-500" }
const _hoisted_3 = { class: "border-t p-4 dark:border-gray-900" }
const _hoisted_4 = { class: "text-secondary" }
const _hoisted_5 = { class: "steps text-sm" }
const _hoisted_6 = ["innerHTML"]
_popScopeId()

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Pool } from '@/services/pool/types';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();

/**
 * COMPUTED
 */
const steps = computed(() => [
  t('getVeBAL.howToLock.steps.lock', [props.lockablePoolTokenInfo.symbol]),
  t('getVeBAL.howToLock.earn.boost'),
  t('getVeBAL.howToLock.earn.voting'),
]);

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!

  return (_openBlock(), _createBlock(_component_BalAccordion, {
    class: "mt-4",
    showSectionBorder: false,
    sections: [
      {
        title: _ctx.$t('getVeBAL.howToLock.title'),
        id: 'how-to-lock',
        handle: 'how-to-lock-handle',
      },
    ]
  }, {
    "how-to-lock-handle": _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('getVeBAL.howToLock.title')), 1),
        _createVNode(_component_BalIcon, {
          name: "chevron-down",
          class: "text-blue-500 transition-colors group-hover:text-pink-500"
        })
      ])
    ]),
    "how-to-lock": _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ol", _hoisted_5, [
            _createElementVNode("li", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('getVeBAL.howToLock.steps.investPart1')) + " ", 1),
              _createVNode(_component_BalLink, {
                tag: "router-link",
                to: { name: 'invest', params: { id: __props.lockablePool.id } },
                external: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.lockablePoolTokenInfo.symbol), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('getVeBAL.howToLock.steps.investPart2')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(steps), (step, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                innerHTML: step
              }, null, 8, _hoisted_6))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["sections"]))
}
}

})