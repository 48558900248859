import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "ml-2" }

import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider';
import { parseUnits } from '@ethersproject/units';
import { format } from 'date-fns';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import { LockType } from '@/components/forms/lock_actions/LockForm/types';
import useConfig from '@/composables/useConfig';
import useEthers from '@/composables/useEthers';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { dateTimeLabelFor } from '@/composables/useTime';
import useTokenApprovalActions from '@/composables/useTokenApprovalActions';
import useTransactions from '@/composables/useTransactions';
import { balancerContractsService } from '@/services/balancer/contracts/balancer-contracts.service';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';
import { TokenInfo } from '@/types/TokenList';
import { TransactionActionInfo } from '@/types/transactions';

/**
 * TYPES
 */
type Props = {
  lockablePoolTokenInfo: TokenInfo;
  lockAmount: string;
  lockEndDate: string;
  lockType: LockType[];
};

type LockActionState = {
  init: boolean;
  confirming: boolean;
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    lockablePoolTokenInfo: { type: null, required: true },
    lockAmount: { type: String, required: true },
    lockEndDate: { type: String, required: true },
    lockType: { type: Array, required: true }
  } as unknown as undefined,
  emits: ["success"] as unknown as undefined,
  setup(__props: {
  lockablePoolTokenInfo: TokenInfo;
  lockAmount: string;
  lockEndDate: string;
  lockType: LockType[];
}, { emit }: { emit: ({
  (e: 'success', value: LockActionState[]): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const lockActionStates = reactive<LockActionState[]>(
  props.lockType.map(() => ({
    init: false,
    confirming: false,
    confirmed: false,
    confirmedAt: '',
  }))
);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { networkConfig } = useConfig();
const { getProvider, explorerLinks } = useWeb3();
const { addTransaction } = useTransactions();
const { txListener, getTxConfirmedAt } = useEthers();
const { getTokenApprovalActionsForSpender } = useTokenApprovalActions(
  [props.lockablePoolTokenInfo.address],
  ref([props.lockAmount])
);
const { fNum2 } = useNumbers();

const lockActions = props.lockType.map((lockType, actionIndex) => ({
  label: t(`getVeBAL.previewModal.actions.${lockType}.label`, [
    format(new Date(props.lockEndDate), PRETTY_DATE_FORMAT),
  ]),
  loadingLabel: t(`getVeBAL.previewModal.actions.${lockType}.loadingLabel`),
  confirmingLabel: t(`getVeBAL.previewModal.actions.${lockType}.confirming`),
  action: () => submit(lockType, actionIndex),
  stepTooltip: t(`getVeBAL.previewModal.actions.${lockType}.tooltip`),
}));

const actions = ref<TransactionActionInfo[]>([...lockActions]);

/**
 * COMPUTED
 */
const lockActionStatesConfirmed = computed(() =>
  lockActionStates.every(lockActionState => lockActionState.confirmed)
);

/**
 * METHODS
 */
async function handleTransaction(
  tx: TransactionResponse,
  lockType: LockType,
  actionIndex: number
): Promise<void> {
  addTransaction({
    id: tx.hash,
    type: 'tx',
    action: lockType,
    summary:
      lockType === LockType.EXTEND_LOCK
        ? t('transactionSummary.extendLock', [
            format(new Date(props.lockEndDate), PRETTY_DATE_FORMAT),
          ])
        : `${fNum2(props.lockAmount, FNumFormats.token)} ${
            props.lockablePoolTokenInfo.symbol
          }`,
    details: {
      lockAmount: props.lockAmount,
      lockEndDate: props.lockEndDate,
      lockType,
    },
  });

  lockActionStates[actionIndex].confirmed = await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      lockActionStates[actionIndex].confirming = false;
      lockActionStates[actionIndex].receipt = receipt;

      const confirmedAt = await getTxConfirmedAt(receipt);
      lockActionStates[actionIndex].confirmedAt = dateTimeLabelFor(confirmedAt);
    },
    onTxFailed: () => {
      lockActionStates[actionIndex].confirming = false;
    },
  });
}

async function submit(lockType: LockType, actionIndex: number) {
  try {
    let tx: TransactionResponse;
    lockActionStates[actionIndex].init = true;

    if (lockType === LockType.CREATE_LOCK) {
      tx = await balancerContractsService.veBAL.createLock(
        getProvider(),
        props.lockAmount,
        props.lockEndDate
      );
    } else if (lockType === LockType.EXTEND_LOCK) {
      tx = await balancerContractsService.veBAL.extendLock(
        getProvider(),
        props.lockEndDate
      );
    } else if (lockType === LockType.INCREASE_LOCK) {
      tx = await balancerContractsService.veBAL.increaseLock(
        getProvider(),
        props.lockAmount
      );
    } else {
      throw new Error('Unsupported lockType provided');
    }

    lockActionStates[actionIndex].init = false;
    lockActionStates[actionIndex].confirming = true;

    console.log('Receipt', tx);

    handleTransaction(tx, lockType, actionIndex);
    return tx;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * WATCHERS
 */
watch(lockActionStatesConfirmed, () => {
  if (lockActionStatesConfirmed.value) {
    emit('success', lockActionStates);
  }
});

/**
 * LIFECYCLE
 */
onBeforeMount(async () => {
  const approvalAmount = parseUnits(
    props.lockAmount,
    props.lockablePoolTokenInfo.decimals
  ).toString();

  const approvalActions = await getTokenApprovalActionsForSpender(
    configService.network.addresses.veBAL,
    approvalAmount
  );

  actions.value.unshift(...approvalActions);
});

return (_ctx: any,_cache: any) => {
  const _component_BalActionSteps = _resolveComponent("BalActionSteps")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(lockActionStatesConfirmed))
      ? (_openBlock(), _createBlock(_component_BalActionSteps, {
          key: 0,
          actions: actions.value
        }, null, 8, ["actions"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lockActionStates), (lockActionState, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "mt-4 flex items-center justify-between text-sm text-gray-400 dark:text-gray-600"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_BalIcon, { name: "clock" }),
                _createElementVNode("span", _hoisted_2, _toDisplayString(lockActionState.confirmedAt), 1)
              ]),
              (lockActionState.receipt)
                ? (_openBlock(), _createBlock(_component_BalLink, {
                    key: 0,
                    href: _unref(explorerLinks).txLink(lockActionState.receipt.transactionHash),
                    external: "",
                    noStyle: "",
                    class: "group flex items-center"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
                      _createVNode(_component_BalIcon, {
                        name: "arrow-up-right",
                        size: "sm",
                        class: "ml-px transition-colors group-hover:text-pink-500"
                      })
                    ]),
                    _: 2
                  }, 1032, ["href"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'vebal' },
            color: "gray",
            outline: "",
            block: "",
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('getVeBAL.previewModal.returnToVeBalPage')), 1)
            ]),
            _: 1
          })
        ], 64))
  ]))
}
}

})