import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "pb-2 font-semibold" }
const _hoisted_3 = { class: "text-secondary mt-2 flex px-1 text-sm" }
const _hoisted_4 = ["onClick"]

import { addWeeks, format } from 'date-fns';
import { computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';

import { INPUT_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';

import useLockState from '../../../composables/useLockState';

/**
 * TYPES
 */
type Props = {
  minLockEndDateTimestamp: number;
  maxLockEndDateTimestamp: number;
  veBalLockInfo: VeBalLockInfo;
};

/**
 * COMPOSABLES
 */


export default _defineComponent({
  props: {
    minLockEndDateTimestamp: { type: Number, required: true },
    maxLockEndDateTimestamp: { type: Number, required: true },
    veBalLockInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  minLockEndDateTimestamp: number;
  maxLockEndDateTimestamp: number;
  veBalLockInfo: VeBalLockInfo;
}) {

const props = __props
const { t } = useI18n();

/**
 * PROPS
 */


/**
 * STATE
 */
const { lockEndDate } = useLockState();

/**
 * COMPUTED
 */
const lockDates = computed(() => [
  {
    label: t('getVeBAL.lockForm.lockPeriods.2w'),
    action: () => updateLockEndDate(props.minLockEndDateTimestamp),
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.1m'),
    action: () =>
      updateLockEndDate(addWeeks(props.minLockEndDateTimestamp, 4).getTime()),
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.3m'),
    action: () =>
      updateLockEndDate(addWeeks(props.minLockEndDateTimestamp, 12).getTime()),
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.6m'),
    action: () =>
      updateLockEndDate(addWeeks(props.minLockEndDateTimestamp, 24).getTime()),
  },
  {
    label: t('getVeBAL.lockForm.lockPeriods.1y'),
    action: () => {
      lockEndDate.value = formatDateInput(props.maxLockEndDateTimestamp);
    },
  },
]);

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  lockEndDate.value = props.veBalLockInfo?.hasExistingLock
    ? formatDateInput(props.veBalLockInfo.lockedEndDate)
    : formatDateInput(props.maxLockEndDateTimestamp);
});

/**
 * METHODS
 */
function updateLockEndDate(timestamp: number) {
  lockEndDate.value = formatDateInput(
    Math.min(timestamp, props.maxLockEndDateTimestamp)
  );
}

function formatDateInput(date: Date | number) {
  return format(date, INPUT_DATE_FORMAT);
}

return (_ctx: any,_cache: any) => {
  const _component_BalTextInput = _resolveComponent("BalTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('getVeBAL.lockForm.lockEndDate.title')), 1)
    ]),
    _createVNode(_component_BalTextInput, {
      modelValue: _unref(lockEndDate),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(lockEndDate) ? lockEndDate.value = $event : null)),
      name: "lockEndDate",
      type: "date",
      min: formatDateInput(__props.minLockEndDateTimestamp),
      max: formatDateInput(__props.maxLockEndDateTimestamp),
      step: "7"
    }, null, 8, ["modelValue", "min", "max"]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lockDates), (lockDate, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mr-3 cursor-pointer hover:text-pink-500 focus:text-pink-500",
          onClick: lockDate.action
        }, " ~" + _toDisplayString(lockDate.label), 9, _hoisted_4))
      }), 128))
    ])
  ]))
}
}

})