import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "pb-2 font-semibold" }

import { computed } from 'vue';

import TokenInput from '@/components/inputs/TokenInput/TokenInput.vue';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import { TokenInfo } from '@/types/TokenList';

import useLockState from '../../../composables/useLockState';

/**
 * TYPES
 */
type Props = {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    lockablePool: { type: null, required: true },
    lockablePoolTokenInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockablePool: Pool;
  lockablePoolTokenInfo: TokenInfo;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { lockAmount } = useLockState();

/**
 * COMPUTED
 */
const lockAmountFiatValue = computed(() =>
  bnum(props.lockablePool.totalLiquidity)
    .div(props.lockablePool.totalShares)
    .times(lockAmount.value)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('getVeBAL.lockForm.lockAmount.title')), 1)
    ]),
    _createVNode(TokenInput, {
      amount: _unref(lockAmount),
      "onUpdate:amount": _cache[0] || (_cache[0] = ($event: any) => (_isRef(lockAmount) ? lockAmount.value = $event : null)),
      address: __props.lockablePoolTokenInfo.address,
      tokenValue: _unref(lockAmountFiatValue),
      fixedToken: "",
      name: "lockAmount"
    }, null, 8, ["amount", "address", "tokenValue"])
  ]))
}
}

})